import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
import CaptionedImage from "../../components/CaptionedImage";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <CaptionedImage filename="sfsymbols-header.png" alt="iOS 13 and 14 side-by-side comparison of a sample app showing two symbols, one of which shows only in the iOS 14 app." max={600} caption="" mdxType="CaptionedImage" />
    <p><a parentName="p" {...{
        "href": "https://developer.apple.com/design/human-interface-guidelines/sf-symbols/overview/"
      }}>{`SF Symbols`}</a>{`, the standardized icon system for the Apple ecosystem, got some great upgrades this year. In addition to macOS support, there are almost a thousand `}<em parentName="p">{`more`}</em>{` symbols available, and there are some changes in the symbol names. Since I spent a little while updating `}<a parentName="p" {...{
        "href": "https://sfsymbols.com"
      }}>{`sfsymbols.com`}</a>{` with the new names, I thought I'd write a quick post about some of the changes.`}</p>
    <h1 {...{
      "id": "new-symbols"
    }}>{`New Symbols`}</h1>
    <p>{`The latest release of SF Symbols is "SF Symbols 2.1" - the symbols can be viewed in an app which you can download from `}<a parentName="p" {...{
        "href": "https://developer.apple.com/sf-symbols/"
      }}>{`Apple's Website`}</a>{` (you can view the symbol names on `}<a parentName="p" {...{
        "href": "https://sfsymbols.com"
      }}>{`sfsymbols.com`}</a>{`, but displaying the images there `}<a parentName="p" {...{
        "href": "/blog/taking-down-sfsymbols/"
      }}>{`isn't allowed under their usage guidelines`}</a>{`.). The previous version, SF Symbols 1.1, had 1,672 symbols - the new version has 2,612, almost a thousand more. I don't have a guarantee about what version of iOS supports each of the symbols, but the website says "These new symbols are available in apps running the beta versions of iOS 14, iPadOS 14, or macOS Big Sur", so ostensibly as long as you're on 14+ you can assume the symbols are available for use.`}</p>
    <p>{`There are a bunch of new symbols - you can view the changes `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/sfsymbols.com/commit/e50e7df8a72f74ac8cb42ccefc710d67671d7f78"
      }}>{`here`}</a>{`, but I'll include a list of new symbol names at the bottom of this post in case someone Googles for them. Some additions are relevant to recent events (like `}<inlineCode parentName="p">{`aqi.high`}</inlineCode>{`), new products (like `}<inlineCode parentName="p">{`appclip`}</inlineCode>{`), or just more general objects (like `}<inlineCode parentName="p">{`building`}</inlineCode>{`). My personal favorite is `}<inlineCode parentName="p">{`candybarphone`}</inlineCode>{`.`}</p>
    <p>{`Some new symbols come with new `}<a parentName="p" {...{
        "href": "https://developer.apple.com/design/human-interface-guidelines/sf-symbols/overview/#symbols-for-use-as-is"
      }}>{`restrictions`}</a>{` as well: for example, you're not supposed to use the `}<inlineCode parentName="p">{`airpodspro`}</inlineCode>{` symbol to represent anything other than Apple's AirPods Pro product (both the SF Symbols app and sfsymbols.com annotate each symbols with their restrictions).`}</p>
    <h1 {...{
      "id": "compatibility"
    }}>{`Compatibility`}</h1>
    <p>{`So, what happens when you try to use a symbol that's not supported? Generally, it fails silently. `}<inlineCode parentName="p">{`UIImage(systemName:)`}</inlineCode>{` will return nil, and `}<inlineCode parentName="p">{`Image(systemName:)`}</inlineCode>{` in SwiftUI will insert an `}<inlineCode parentName="p">{`Image`}</inlineCode>{` token into the tree, but won't display any image.`}</p>
    <p>{`Interestingly, some symbols have changed names! For example, the `}<inlineCode parentName="p">{`arrow.2.circlepath`}</inlineCode>{` symbol is not listed in the SF Symbols app anymore - that glyph is called `}<inlineCode parentName="p">{`arrow.triangle.2.circlepath`}</inlineCode>{` now. Not to worry - you can still use the old names with the `}<inlineCode parentName="p">{`systemName`}</inlineCode>{` methods, and the system will use the same glyph (at least, it did for the ones that I tested).`}</p>
    <p>{`You can determine which version a symbol is available in using the "info" button in the SF Symbols app, and these renamed/deprecated symbol names also shown in the info panel under "Deprecated Names."`}</p>
    <CaptionedImage filename="sfsymbols-deprecation.png" alt="Screenshot of the SF symbols app deprecation information about a symbol." max={300} caption="" mdxType="CaptionedImage" />
    <h1 {...{
      "id": "appendix-new-symbol-names"
    }}>{`Appendix: New Symbol Names`}</h1>
    <p>{`The following is a list of the symbols which are present in SF Symbols 2.1 but not SF Symbols 1.1.`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`4k.tv`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`4k.tv.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`a.magnify`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`abc`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`airplane.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`airplane.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`airpod.left`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`airpod.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`airpodpro.left`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`airpodpro.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`airpods`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`airpodspro`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`airport.express`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`airport.extreme`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`airport.extreme.tower`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`amplifier`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`appclip`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`applelogo`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`applescript`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`applescript.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`appletv`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`appletv.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`applewatch`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`applewatch.radiowaves.left.and.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`applewatch.slash`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`applewatch.watchface`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`apps.ipad`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`apps.ipad.landscape`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`apps.iphone`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`apps.iphone.badge.plus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`apps.iphone.landscape`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`aqi.high`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`aqi.low`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`aqi.medium`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`archivebox.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`archivebox.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.backward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.backward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.backward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.backward.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.backward.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.clockwise.heart`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.clockwise.heart.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.app`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.app.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.backward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.backward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.backward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.backward.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.backward.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.forward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.forward.and.arrow.up.backward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.forward.and.arrow.up.backward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.forward.and.arrow.up.backward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.forward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.forward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.forward.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.forward.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.heart`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.heart.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.right.and.arrow.up.left.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.down.right.and.arrow.up.left.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.forward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.forward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.forward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.forward.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.forward.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.left.and.right.righttriangle.left.righttriangle.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.left.and.right.righttriangle.left.righttriangle.right.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.left.arrow.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.left.arrow.right.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.left.arrow.right.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.left.arrow.right.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.left.arrow.right.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.rectanglepath`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.right.doc.on.clipboard`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.triangle.2.circlepath`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.triangle.2.circlepath.camera`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.triangle.2.circlepath.camera.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.triangle.2.circlepath.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.triangle.2.circlepath.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.triangle.2.circlepath.doc.on.clipboard`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.triangle.branch`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.triangle.capsulepath`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.triangle.merge`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.triangle.pull`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.triangle.swap`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.triangle.turn.up.right.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.triangle.turn.up.right.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.triangle.turn.up.right.diamond`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.triangle.turn.up.right.diamond.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.turn.up.forward.iphone`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.turn.up.forward.iphone.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.and.down.and.arrow.left.and.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.and.down.righttriangle.up.fill.righttriangle.down.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.and.down.righttriangle.up.righttriangle.down`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.and.person.rectangle.portrait`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.and.person.rectangle.turn.left`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.and.person.rectangle.turn.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.backward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.backward.and.arrow.down.forward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.backward.and.arrow.down.forward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.backward.and.arrow.down.forward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.backward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.backward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.backward.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.backward.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.doc.on.clipboard`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.forward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.forward.app`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.forward.app.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.forward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.forward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.forward.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.forward.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.heart`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.heart.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.left.and.arrow.down.right.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.left.and.arrow.down.right.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.left.and.down.right.and.arrow.up.right.and.down.left`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.left.and.down.right.magnifyingglass`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.message`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.message.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.right.and.arrow.down.left.rectangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.up.right.and.arrow.down.left.rectangle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.uturn.backward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.uturn.backward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.uturn.backward.circle.badge.ellipsis`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.uturn.backward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.uturn.backward.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.uturn.backward.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.uturn.forward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.uturn.forward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.uturn.forward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.uturn.forward.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrow.uturn.forward.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.bounce.forward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.bounce.forward.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.bounce.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.bounce.right.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.turn.up.backward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.turn.up.backward.2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.turn.up.backward.2.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.turn.up.backward.2.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.turn.up.backward.2.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.turn.up.backward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.turn.up.backward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.turn.up.backward.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.turn.up.forward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.turn.up.forward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.turn.up.forward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.turn.up.forward.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.turn.up.left.2.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.turn.up.left.2.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.zigzag.forward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.zigzag.forward.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.zigzag.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowshape.zigzag.right.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowtriangle.backward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowtriangle.backward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowtriangle.backward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowtriangle.backward.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowtriangle.backward.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowtriangle.backward.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowtriangle.forward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowtriangle.forward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowtriangle.forward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowtriangle.forward.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowtriangle.forward.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowtriangle.forward.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowtriangle.left.and.line.vertical.and.arrowtriangle.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowtriangle.left.fill.and.line.vertical.and.arrowtriangle.right.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowtriangle.right.and.line.vertical.and.arrowtriangle.left`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`arrowtriangle.right.fill.and.line.vertical.and.arrowtriangle.left.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`at.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`at.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`atom`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`backward.frame`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`backward.frame.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`badge.plus.radiowaves.forward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bag.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bag.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`banknote`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`banknote.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`barometer`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`battery.100.bolt`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bell.badge`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bell.badge.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bell.slash.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bell.slash.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bicycle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bicycle.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bicycle.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`binoculars`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`binoculars.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bolt.car`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bolt.car.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bolt.fill.batteryblock`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bolt.fill.batteryblock.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bolt.heart`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bolt.heart.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bolt.slash.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bolt.slash.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bonjour`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`book.closed`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`book.closed.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bookmark.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bookmark.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bookmark.slash`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bookmark.slash.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`books.vertical`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`books.vertical.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`brazilianrealsign.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`brazilianrealsign.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`brazilianrealsign.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`brazilianrealsign.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`building`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`building.2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`building.2.crop.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`building.2.crop.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`building.2.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`building.columns`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`building.columns.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`building.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bus.doubledecker`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bus.doubledecker.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`bus.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`calendar.badge.clock`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`calendar.badge.exclamationmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`camera.aperture`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`camera.badge.ellipsis`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`camera.fill.badge.ellipsis`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`camera.filters`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`camera.metering.center.weighted`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`camera.metering.center.weighted.average`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`camera.metering.matrix`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`camera.metering.multispot`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`camera.metering.none`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`camera.metering.partial`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`camera.metering.spot`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`camera.metering.unknown`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`candybarphone`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`capsule.portrait`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`capsule.portrait.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`car.2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`car.2.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`car.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`car.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cart.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cart.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`case`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`case.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`character`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`character.book.closed`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`character.book.closed.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`chart.bar.doc.horizontal`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`chart.bar.doc.horizontal.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`chart.bar.xaxis`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`checkerboard.rectangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`checkmark.icloud`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`checkmark.icloud.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`checkmark.rectangle.portrait`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`checkmark.rectangle.portrait.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`chevron.backward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`chevron.backward.2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`chevron.backward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`chevron.backward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`chevron.backward.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`chevron.backward.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`chevron.forward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`chevron.forward.2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`chevron.forward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`chevron.forward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`chevron.forward.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`chevron.forward.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circle.bottomhalf.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circle.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circle.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circle.dashed`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circle.dashed.inset.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circle.fill.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circle.grid.cross`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circle.grid.cross.down.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circle.grid.cross.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circle.grid.cross.left.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circle.grid.cross.right.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circle.grid.cross.up.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circle.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circle.tophalf.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circlebadge`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circlebadge.2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circlebadge.2.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circlebadge.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circles.hexagongrid`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circles.hexagongrid.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circles.hexagonpath`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`circles.hexagonpath.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`clock.arrow.2.circlepath`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`clock.arrow.circlepath`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`comb`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`comb.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`command.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`command.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`command.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`command.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cone`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cone.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`contextualmenu.and.cursorarrow`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cpu`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`creditcard.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`creditcard.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cross`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cross.case`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cross.case.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cross.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cross.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cross.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`crown`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`crown.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cube.transparent`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cube.transparent.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`curlybraces`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`curlybraces.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`curlybraces.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cursorarrow`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cursorarrow.and.square.on.square.dashed`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cursorarrow.click`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cursorarrow.click.2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cursorarrow.click.badge.clock`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cursorarrow.motionlines`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cursorarrow.motionlines.click`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cursorarrow.rays`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cursorarrow.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cylinder`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cylinder.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cylinder.split.1x2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`cylinder.split.1x2.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`deskclock`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`deskclock.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dial.max`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dial.max.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dial.min`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dial.min.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`diamond`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`diamond.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`die.face.1`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`die.face.1.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`die.face.2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`die.face.2.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`die.face.3`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`die.face.3.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`die.face.4`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`die.face.4.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`die.face.5`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`die.face.5.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`die.face.6`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`die.face.6.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`directcurrent`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`display`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`display.2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`display.trianglebadge.exclamationmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`doc.append.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`doc.badge.ellipsis`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`doc.badge.gearshape`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`doc.badge.gearshape.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`doc.badge.plus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`doc.fill.badge.ellipsis`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`doc.fill.badge.plus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`doc.plaintext.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`doc.richtext.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`doc.text.below.ecg`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`doc.text.below.ecg.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`doc.text.fill.viewfinder`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`doc.zipper`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dock.arrow.down.rectangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dock.arrow.up.rectangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dock.rectangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dot.arrowtriangles.up.right.down.left.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dot.circle.and.cursorarrow`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dot.radiowaves.forward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dot.squareshape`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dot.squareshape.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dot.squareshape.split.2x2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dpad`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dpad.down.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dpad.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dpad.left.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dpad.right.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`dpad.up.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`drop`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`drop.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ear.badge.checkmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ear.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ear.trianglebadge.exclamationmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`earpods`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`eject.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`eject.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ellipsis.bubble`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ellipsis.bubble.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ellipsis.rectangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ellipsis.rectangle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`envelope.arrow.triangle.branch`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`envelope.arrow.triangle.branch.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`envelope.badge.shield.leadinghalf.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`envelope.fill.badge.shield.trailinghalf.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`esim`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`esim.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`exclamationmark.2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`exclamationmark.3`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`exclamationmark.applewatch`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`exclamationmark.arrow.circlepath`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`exclamationmark.arrow.triangle.2.circlepath`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.badge.checkmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.badge.icloud`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.badge.minus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.badge.person.crop`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.badge.plus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.badge.timemachine`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.badge.wifi`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.badge.xmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.connected.to.line.below`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.connected.to.line.below.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.fill.badge.checkmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.fill.badge.icloud`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.fill.badge.minus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.fill.badge.person.crop`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.fill.badge.plus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.fill.badge.timemachine`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.fill.badge.wifi`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`externaldrive.fill.badge.xmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`eye.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`eye.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`eyebrow`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`eyes`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`eyes.inverse`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`face.dashed`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`face.dashed.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`face.smiling`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`face.smiling.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`faxmachine`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`fiberchannel`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`figure.stand`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`figure.stand.line.dotted.figure.stand`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`figure.walk`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`figure.walk.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`figure.walk.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`figure.walk.diamond`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`figure.walk.diamond.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`figure.wave`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`figure.wave.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`figure.wave.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`filemenu.and.cursorarrow`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`filemenu.and.selection`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`flag.badge.ellipsis`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`flag.badge.ellipsis.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`flag.slash.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`flag.slash.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`flipphone`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`fn`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`folder.badge.gear`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`folder.badge.questionmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`folder.fill.badge.gear`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`folder.fill.badge.questionmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`forward.frame`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`forward.frame.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`gearshape`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`gearshape.2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`gearshape.2.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`gearshape.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`gift.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`gift.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`giftcard`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`giftcard.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`graduationcap`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`graduationcap.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`greetingcard`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`greetingcard.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`guitars.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`gyroscope`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`h.square.fill.on.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`h.square.on.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hand.point.down`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hand.point.down.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hand.point.up`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hand.point.up.braille`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hand.point.up.braille.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hand.point.up.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hand.point.up.left`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hand.point.up.left.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hand.tap`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hand.tap.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hand.wave`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hand.wave.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hands.clap`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hands.clap.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hands.sparkles`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hands.sparkles.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`headphones.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`headphones.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hearingaid.ear`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`heart.text.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`heart.text.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hifispeaker.2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hifispeaker.2.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hifispeaker.and.homepod`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hifispeaker.and.homepod.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`highlighter`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`homekit`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`homepod`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`homepod.2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`homepod.2.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`homepod.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`hourglass.badge.plus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`house.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`house.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`infinity`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`infinity.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`infinity.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`internaldrive`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`internaldrive.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ipad`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ipad.badge.play`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ipad.homebutton`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ipad.homebutton.badge.play`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ipad.homebutton.landscape`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ipad.homebutton.landscape.badge.play`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ipad.landscape`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ipad.landscape.badge.play`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`iphone`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`iphone.badge.play`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`iphone.homebutton`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`iphone.homebutton.badge.play`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`iphone.homebutton.landscape`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`iphone.homebutton.radiowaves.left.and.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`iphone.homebutton.slash`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`iphone.landscape`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`iphone.radiowaves.left.and.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`iphone.slash`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ipod`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ipodshuffle.gen1`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ipodshuffle.gen2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ipodshuffle.gen3`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ipodshuffle.gen4`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ipodtouch`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ipodtouch.landscape`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`j.square.fill.on.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`j.square.on.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`k`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`key`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`key.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`key.icloud`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`key.icloud.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`keyboard.badge.ellipsis`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`keyboard.chevron.compact.left`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`keyboard.macwindow`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`keyboard.onehanded.left`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`keyboard.onehanded.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`l.joystick`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`l.joystick.down`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`l.joystick.down.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`l.joystick.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`l.rectangle.roundedbottom`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`l.rectangle.roundedbottom.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`l1.rectangle.roundedbottom`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`l1.rectangle.roundedbottom.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`l2.rectangle.roundedtop`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`l2.rectangle.roundedtop.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ladybug`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ladybug.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`laptopcomputer`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`laptopcomputer.and.iphone`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lasso.sparkles`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`latch.2.case`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`latch.2.case.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lb.rectangle.roundedbottom`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lb.rectangle.roundedbottom.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`leaf`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`leaf.arrow.triangle.circlepath`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`leaf.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`level`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`level.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lifepreserver`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lifepreserver.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`line.3.crossed.swirl.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`line.3.crossed.swirl.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`line.diagonal`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`line.diagonal.arrow`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`line.horizontal.2.decrease.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`line.horizontal.2.decrease.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`line.horizontal.3.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`line.horizontal.3.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`line.horizontal.star.fill.line.horizontal`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lineweight`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`link.badge.plus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`list.and.film`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`list.bullet.rectangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`list.star`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`list.triangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`livephoto.badge.a`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`location.fill.viewfinder`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`location.viewfinder`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lock.applewatch`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lock.doc`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lock.doc.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lock.rectangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lock.rectangle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lock.rectangle.on.rectangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lock.rectangle.on.rectangle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lock.rectangle.stack`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lock.rectangle.stack.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lock.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lock.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lock.square.stack`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lock.square.stack.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`loupe`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lt.rectangle.roundedtop`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lt.rectangle.roundedtop.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lungs`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`lungs.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`macmini`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`macmini.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`macpro.gen1`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`macpro.gen2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`macpro.gen2.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`macpro.gen3`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`macpro.gen3.server`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`macwindow.badge.plus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`macwindow.on.rectangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`mail`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`mail.and.text.magnifyingglass`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`mail.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`mail.stack`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`mail.stack.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`megaphone`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`megaphone.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`memorychip`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`menubar.arrow.down.rectangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`menubar.arrow.up.rectangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`menubar.dock.rectangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`menubar.dock.rectangle.badge.record`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`menubar.rectangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`metronome.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`minus.diamond`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`minus.diamond.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`minus.plus.batteryblock`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`minus.plus.batteryblock.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`minus.rectangle.portrait`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`minus.rectangle.portrait.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`mosaic`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`mosaic.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`mount`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`mount.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`mouth`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`mouth.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`move.3d`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`music.note.house`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`music.note.house.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`music.quarternote.3`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`mustache`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`mustache.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`network`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`newspaper`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`newspaper.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`nose`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`nose.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`note`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`note.text`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`note.text.badge.plus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`octagon`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`octagon.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`opticaldisc`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`opticaldiscdrive`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`opticaldiscdrive.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`oval`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`oval.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`oval.portrait`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`oval.portrait.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`paintbrush.pointed`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`paintbrush.pointed.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`paintpalette`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`paintpalette.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`paperclip.badge.ellipsis`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`paperplane.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`paperplane.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`paragraphsign`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`pc`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`pencil.tip.crop.circle.badge.arrow.forward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.2.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.2.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.and.arrow.left.and.arrow.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.crop.circle.badge.exclamationmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.crop.circle.badge.questionmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.crop.circle.fill.badge.exclamationmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.crop.circle.fill.badge.questionmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.crop.square.fill.and.at.rectangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.fill.and.arrow.left.and.arrow.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.fill.badge.minus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.fill.badge.plus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.fill.checkmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.fill.questionmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.fill.turn.down`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.fill.turn.left`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.fill.turn.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.fill.viewfinder`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`person.fill.xmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`phone.bubble.left`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`phone.bubble.left.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`phone.connection`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`phone.fill.connection`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`photo.on.rectangle.angled`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`photo.tv`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`pianokeys`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`pianokeys.inverse`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`pills`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`pills.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`pip`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`pip.enter`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`pip.exit`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`pip.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`pip.remove`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`pip.swap`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`placeholdertext.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`play.slash`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`play.slash.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`play.tv`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`play.tv.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`plus.diamond`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`plus.diamond.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`plus.message`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`plus.message.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`plus.rectangle.fill.on.folder.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`plus.rectangle.on.folder`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`plus.rectangle.portrait`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`plus.rectangle.portrait.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`plus.viewfinder`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`point.fill.topleft.down.curvedto.point.fill.bottomright.up`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`point.topleft.down.curvedto.point.bottomright.up`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`poweroff`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`poweron`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`powersleep`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`printer.dotmatrix`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`printer.dotmatrix.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`printer.dotmatrix.fill.and.paper.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`printer.fill.and.paper.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`puzzlepiece`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`puzzlepiece.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`pyramid`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`pyramid.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`questionmark.folder`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`questionmark.folder.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`questionmark.square.dashed`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`r.joystick`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`r.joystick.down`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`r.joystick.down.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`r.joystick.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`r.rectangle.roundedbottom`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`r.rectangle.roundedbottom.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`r.square.fill.on.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`r.square.on.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`r1.rectangle.roundedbottom`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`r1.rectangle.roundedbottom.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`r2.rectangle.roundedtop`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`r2.rectangle.roundedtop.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`radio`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`radio.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rb.rectangle.roundedbottom`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rb.rectangle.roundedbottom.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`record.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`record.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.3.offgrid.bubble.left`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.3.offgrid.bubble.left.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.and.pencil.and.ellipsis`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.and.text.magnifyingglass`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.arrowtriangle.2.inward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.arrowtriangle.2.outward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.badge.minus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.badge.person.crop`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.badge.plus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.bottomthird.inset.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.center.inset.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.connected.to.line.below`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.dashed`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.dashed.and.paperclip`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.dashed.badge.record`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.fill.badge.minus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.fill.badge.person.crop`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.fill.badge.plus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.fill.on.rectangle.fill.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.fill.on.rectangle.fill.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.fill.on.rectangle.fill.slash.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.inset.bottomleft.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.inset.bottomright.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.inset.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.inset.topleft.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.inset.topright.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.lefthalf.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.lefthalf.inset.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.lefthalf.inset.fill.arrow.left`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.leftthird.inset.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.on.rectangle.slash`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.portrait`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.portrait.arrowtriangle.2.inward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.portrait.arrowtriangle.2.outward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.portrait.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.righthalf.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.righthalf.inset.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.righthalf.inset.fill.arrow.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.rightthird.inset.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.roundedbottom`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.roundedbottom.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.roundedtop`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.roundedtop.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.slash`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.slash.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.split.1x2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.split.1x2.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.split.2x1`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.split.2x1.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.split.2x2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.split.2x2.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rectangle.topthird.inset`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`repeat.1.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`repeat.1.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`repeat.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`repeat.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`restart`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`restart.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rotate.3d`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rt.rectangle.roundedtop`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`rt.rectangle.roundedtop.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ruler`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ruler.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`scale.3d`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`scalemass`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`scalemass.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`scanner`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`scanner.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`scribble.variable`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`scroll`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`scroll.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`sdcard`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`sdcard.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`seal`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`seal.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`server.rack`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`shadow`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`shekelsign.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`shekelsign.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`shekelsign.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`shekelsign.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`shield.checkerboard`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`shield.lefthalf.fill.slash`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`shippingbox`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`shippingbox.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`shuffle.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`shuffle.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`sidebar.leading`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`sidebar.squares.leading`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`sidebar.squares.left`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`sidebar.squares.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`sidebar.squares.trailing`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`sidebar.trailing`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`signpost.left`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`signpost.left.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`signpost.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`signpost.right.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`simcard`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`simcard.2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`simcard.2.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`simcard.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`sleep`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`slider.horizontal.below.square.fill.and.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`slider.vertical.3`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`sparkle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`sparkles.rectangle.stack`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`sparkles.rectangle.stack.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`sparkles.square.fill.on.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`speaker.slash.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`speaker.slash.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`speaker.wave.1`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`speaker.wave.1.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`speaker.wave.2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`speaker.wave.2.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`speaker.wave.2.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`speaker.wave.2.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`speaker.wave.3`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`speaker.wave.3.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.2.stack.3d`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.2.stack.3d.bottom.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.2.stack.3d.top.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.3.stack.3d`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.3.stack.3d.bottom.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.3.stack.3d.middle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.3.stack.3d.top.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.and.at.rectangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.bottomhalf.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.dashed`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.dashed.inset.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.fill.on.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.fill.text.grid.1x2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.grid.3x1.below.line.grid.1x2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.grid.3x1.fill.below.line.grid.1x2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.grid.3x1.folder.badge.plus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.grid.3x1.folder.fill.badge.plus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.grid.3x3`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.grid.3x3.bottomleft.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.grid.3x3.bottommiddle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.grid.3x3.bottomright.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.grid.3x3.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.grid.3x3.fill.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.grid.3x3.middle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.grid.3x3.middleleft.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.grid.3x3.middleright.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.grid.3x3.topleft.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.grid.3x3.topmiddle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.grid.3x3.topright.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.on.square.dashed`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.on.square.squareshape.controlhandles`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.slash`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.slash.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.split.bottomrightquarter`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.split.bottomrightquarter.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.split.diagonal`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.split.diagonal.2x2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.split.diagonal.2x2.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.split.diagonal.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.stack.3d.down.forward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.stack.3d.down.forward.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.stack.3d.forward.dottedline`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.stack.3d.forward.dottedline.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.stack.3d.up.badge.a`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.stack.3d.up.badge.a.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`square.tophalf.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`squareshape`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`squareshape.controlhandles.on.squareshape.controlhandles`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`squareshape.dashed.squareshape`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`squareshape.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`squareshape.split.2x2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`squareshape.split.2x2.dotted`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`squareshape.split.3x3`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`squareshape.squareshape.dashed`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`star.leadinghalf.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`star.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`star.square.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`staroflife.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`staroflife.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`stethoscope`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`swift`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`switch.2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`tablecells`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`tablecells.badge.ellipsis`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`tablecells.badge.ellipsis.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`tablecells.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`tag.slash`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`tag.slash.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`target`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`teletype.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`teletype.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`terminal`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`terminal.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`text.and.command.macwindow`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`text.below.photo`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`text.below.photo.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`text.book.closed`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`text.book.closed.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`text.magnifyingglass`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`text.redaction`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`textformat.size.larger`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`textformat.size.smaller`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`thermometer.sun.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ticket`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ticket.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`timeline.selection`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`timer.square`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`togglepower`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`torus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`touchid`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`tram`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`tram.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`tram.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`tram.tunnel.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`tray.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`tray.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`triangle.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`triangle.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`tv.and.hifispeaker.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`tv.and.mediabox`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`video.badge.checkmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`video.bubble.left`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`video.bubble.left.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`video.fill.badge.checkmark`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`video.fill.badge.plus`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`wake`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`wallet.pass`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`wallet.pass.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`wave.3.backward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`wave.3.backward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`wave.3.backward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`wave.3.forward`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`wave.3.forward.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`wave.3.forward.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`wave.3.left`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`wave.3.left.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`wave.3.left.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`wave.3.right`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`wave.3.right.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`wave.3.right.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`waveform.path.ecg.rectangle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`waveform.path.ecg.rectangle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`wrench.and.screwdriver`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`wrench.and.screwdriver.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`xmark.bin`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`xmark.bin.circle`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`xmark.bin.circle.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`xmark.bin.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`xmark.diamond`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`xmark.diamond.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`xmark.rectangle.portrait`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`xmark.rectangle.portrait.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`xserve`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`zl.rectangle.roundedtop`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`zl.rectangle.roundedtop.fill`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`zr.rectangle.roundedtop`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`zr.rectangle.roundedtop.fill`}</span></code></pre>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      